@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background: #e8dab2;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.unselected-tab {
  box-shadow: inset 0 -10px 4px -10px rgba(0, 0, 0, 0.31);
}

@keyframes bounce-horizontally {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1e8d0;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #375e6e;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c2f37;
}